import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xl')]: {
            minHeight: "1300px",
        },
    },
    appBarMargin: {
        minHeight: "200px",
        [theme.breakpoints.down('sm')]: {
            minHeight: "100px",
        },
    },
    categoryHeader: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        fontWeight: "700px",
        color: "#2D4C8B",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            fontSize: "12px",
        },
    },
    categoryText: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "14px",
        fontWeight: "400px",
        width: "100%",
        color: "#2D4C8B",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "10px",
            marginLeft: "2px",
        },
    },
    divider: {
        marginTop: "10px",
        marginBottom: "20px",
        borderTop: "2px solid #2D4C8B",
        width: "30%",
        [theme.breakpoints.down('sm')]: {
            marginTop: "4px",
            marginBottom: "8px",
        },
    },
    imageSmall: {
        width: "100%",
        marginBottom: "20px"
    },
    galleryCard: {
        display: "flex",
        position: "relative",
        // justifyContent: "space-around",
        backgroundColor: "#D5E0F6",
        width: "100%",
        height: "200px",
        marginBottom: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "0px",
        [theme.breakpoints.down('xs')]: {
            height: "100px",
            width: "80%",
        },
        "&:hover": {
            opacity: "0.7",
            cursor: "pointer"
        },
        "&:hover > img:hover": {
            opacity: "1",
        },
        "& img": {
            height: "200px",
            verticalAlign: "middle",
            [theme.breakpoints.down('xs')]: {
                maxHeight: "100px",
            },
        }
    }
}));

const categories = {
    loftconv: {
        title: "Loft Conversion with Glass Staircase",
        description: "Loft conversion with 2 bedrooms, a bathroom, and a glass staircase.",
        coverImage: "/gallery/loft-conv-2-bedrooms/1.jpg",
        path: "/gallery/loft-conv-2-bedrooms",
        count: 36,
    },
    firstfix: {
        title: "Loft Conversion/Rear Extension",
        description: "An example of our first-fix carpentry on a large extension and loft conversion.",
        coverImage: "/gallery/firstfix/1.jpg",
        path: "/gallery/firstfix",
        count: 13,
    },
    rearext: {
        title: "Rear Extension with Kitchen",
        description: "We built this rear extension, with Hardie Plank cladding, and fitted kitchen within.",
        coverImage: "/gallery/rear-extension/1.jpg",
        path: "/gallery/rear-extension",
        count: 8,
    },
    vaultedext: {
        title: "Vaulted Roof Rear Extension",
        description: "With bi-fold doors and a gleaming new kitchen.",
        coverImage: "/gallery/vaulted-extension/1.jpg",
        path: "/gallery/vaulted-extension",
        count: 5,
    },
    twostorey: {
        title: "Two Storey Side Extension",
        description: "A side extension over 2 storeys, with new bathroom, bedroom and garage",
        coverImage: "/gallery/two-storey/1.jpg",
        path: "/gallery/two-storey",
        count: 10,
    }
};

const CategoryCard = ({details}) => {
    const [lightbox, setLightbox] = useState({open: false, index: 0});
    const classes = useStyles();

    let images = [];
    for (let i = 1; i <= details.count; i++) {
        images.push(`${details.path}/${i}.jpg`)
    }

    return (
        <React.Fragment>
            <Box className={classes.galleryCard} onClick={() => setLightbox({open: true, index: 0})}>
                <img src={details.coverImage} alt={details.title}/>
                <Box className={classes.categoryText}>
                    <Typography variant={"inherit"}>
                        <p className={classes.categoryHeader}>{details.title}</p>
                        <hr className={classes.divider}/>
                        <Hidden xsDown>
                            {details.description}
                        </Hidden>
                    </Typography>
                </Box>
            </Box>
            { lightbox.open && <Lightbox
                mainSrc={images[lightbox.index]}
                nextSrc={images[(lightbox.index + 1) % images.length]}
                prevSrc={images[(lightbox.index + images.length - 1) % images.length]}
                onMovePrevRequest={() => setLightbox({...lightbox, index: (lightbox.index + images.length - 1) % images.length })}
                onMoveNextRequest={() => setLightbox({...lightbox, index: (lightbox.index +  1) % images.length })}
                onCloseRequest={() => setLightbox({open: false, index: 0})}
                enableZoom={false}
            />}
        </React.Fragment>
    )
};

const Gallery = () => {
    const classes = useStyles();
    return(
        <Container>
            <div className={classes.appBarMargin}/>
        <Grid container classes={{root: classes.root}} spacing={3}>
            {
                Object.keys(categories).map((name, idx) =>
                        <Grid item sm={6} xs={12}>
                        <CategoryCard key={idx} details={categories[name]} />
                        </Grid>
                )
            }
        </Grid>
        </Container>
    )
};

export default Gallery;