import React from 'react';
import './App.css';
import Header from "./Header";
import Body from "./Body";
import Contact from "./Contact";
import Gallery from "./Gallery";
import {BrowserRouter, Switch} from "react-router-dom";
import {Route} from "react-router";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap" rel="stylesheet"/>
            <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
            <ScrollToTop/>
            <Header/>
            <Switch>
                <Route exact path="/" component={Body}/>
                <Route path="/gallery" component={Gallery}/>
            </Switch>
            <Contact/>
        </BrowserRouter>
    </div>
  );
}

export default App;
