import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {AppBar, Box} from "@material-ui/core";
import logo from "./logo-blue.png"
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import EmailIcon from '@material-ui/icons/Email';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#2D4C8B",
        height: "120px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            height: "60px",
        }
    },
    logo: {
        height: "80px",
        paddingLeft: "5%",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0",
            paddingLeft: "0",
            height: "40px",
        }
    },
    toolbar: {
        backgroundColor: fade("#80A2E5",0.42),
        height: "62px",
        display: "flex",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            height: "31px",
            justifyContent: "space-around"
        }
    },
    navItem: {
        width: "160px",
        height: "60px",
        [theme.breakpoints.down('sm')]: {
            width: "80px",
            height: "30px",
        }
    },
    navItemText: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "600",
        letterSpacing: "2px",
        color: "white",
        textDecoration: "none",
        [theme.breakpoints.down('sm')]: {
            fontSize: "10px",
        }
    },
    emailText: {
        paddingRight: "5%",
        fontSize: "16px",
        color: "white",
        fontFamily: "Noto Sans, sans-serif",
        fontWeight: "400",
        letterSpacing: "1px",
        "& a": {
            textDecoration: "none",
            color: "white"
        }
    },
}));

const NavItem = ({text, to}) => {
    const classes = useStyles();
    return (
        <Link to={to} style={{textDecoration: "none"}}>
            <Button classes={{ root: classes.navItem, text: classes.navItemText}} variant={"text"}>
                    {text}
            </Button>
        </Link>
    )
};

const Header = () => {
    const classes = useStyles();
    return (
        <div style={{zIndex: "1", position: "relative"}}>
        <AppBar elevation={0} color={"transparent"}>
            <Box className={classes.root}>
                <div className={classes.logo}>
                    <img style={{height: "100%", width: "auto"}} src={logo} alt="ModernStyleBuildingLogo"/>
                </div>
                <Hidden smDown>
                <Box className={classes.emailText}>
                    <a href="mailto:enquiries@modernstylebuilding.com">
                        <Typography variant={"inherit"}>
                            <EmailIcon style={{verticalAlign: "middle"}}/> enquiries@modernstylebuilding.com
                        </Typography>
                    </a>
                </Box>
                </Hidden>
            </Box>
            <Box className={classes.toolbar}>
                <NavItem text={"Home"} to={"/"}/>
                <NavItem text={"Gallery"} to={"/gallery"}/>
            </Box>
        </AppBar>
        </div>
    )
};

export default Header;