import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    heroPanel: {
        backgroundColor: "#D5E0F6",
        position: "relative"
    },
    logoMini: {
        height: "24px",
        width: "auto",
    },
    heroText: {
        position: "absolute",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        padding: "3%",
        bottom: "60%",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "28px",
        fontWeight: "700",
        color: "#D5E0F6",
        [theme.breakpoints.down('sm')]: {
            top: "15%",
            fontSize: "12px"
        },
        [theme.breakpoints.up('xl')]: {
            top: "15%",
            fontSize: "1.5vw",
        },
    },
    bullets: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "22px",
        fontWeight: "700",
        color: "#D5E0F6",
        lineHeight: "150%",
        [theme.breakpoints.down('sm')]: {
            fontSize: "10px"
        },
    },
    secondPanel: {
        backgroundColor: "#D5E0F6",
        paddingTop: "1%",
        paddingBottom: "3%",
        [theme.breakpoints.up('sm')]: {
            position: "absolute",
            right: "0%",
            bottom: "10%",
            width: "60%"
        }
    },
    thirdPanel: {
        backgroundColor: "white",
        paddingTop: "3%",
        paddingBottom: "3%",
    },
    textImage: {
        height: "220px",
        width: "auto",
        [theme.breakpoints.down('sm')]: {
            height: "140px",
        }
    },
    secondaryText: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        fontWeight: "300",
        color: "#2D4C8B",
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
            paddingLeft: "2%",
            paddingRight: "2%"
        }
    },
    secHeader: {
        fontSize: "20px",
        fontWeight: "600",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
        }
    },
    appBarMargin: {
        minHeight: "120px",
        [theme.breakpoints.down('sm')]: {
            minHeight: "60px",
        },
    }
}));

const Body = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
        <div className={classes.appBarMargin}/>
        <Box className={classes.heroPanel}>
            <img src={"mainHeroImage.jpg"} alt={"ModernStyleBuilding"} style={{width: "100%", verticalAlign: "middle"}}/>
                <Box className={classes.heroText}>
                    <Typography variant={"inherit"}>
                        Modern Style, Traditional Quality<br/>
                        <Box className={classes.bullets}><CheckIcon fontSize={"inherit"}/> Loft Conversions</Box>
                        <Box className={classes.bullets}><CheckIcon fontSize={"inherit"}/> Extensions</Box>
                        <Box className={classes.bullets}><CheckIcon fontSize={"inherit"}/> Bathrooms</Box>
                        <Box className={classes.bullets}><CheckIcon fontSize={"inherit"}/> Kitchens</Box>
                        <Box className={classes.bullets}>... and more</Box>
                    </Typography>
                </Box>
            <Grid container className={classes.secondPanel} justify={"center"}>
                <Grid item sm={10}>
                    <Box className={classes.secondaryText}>
                        <Typography variant={"inherit"}>
                            <p className={classes.secHeader}>Quality craftsmanship is at the heart of everything we do.</p>
                            Please check out our <a href="/gallery">gallery</a> to see a portfolio of previous projects.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

            <Grid container className={classes.thirdPanel} justify={"center"} spacing={2}>
                <Grid item sm={6}>
                    <Box className={classes.secondaryText}>
                        <Typography variant={"inherit"}>
                            <p className={classes.secHeader}>Modern Style Building Ltd. was established in 2016.</p>
                            We are a friendly, reliable team with over 50 years' industry experience.<br/><br/>
                            We specialise in loft conversions and extensions but have the expertise and experience to take on all kinds of building work; from
                            kitchens and bathrooms to steel work, anything you can conceive to realise your interior (and exterior!) design dreams.<br/><br/>
                            We'd love to hear from you!<br/><br/> Feel free to get in touch using the contact details below and we'll be happy to provide a quote.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item container sm={2} alignItems={"center"} justify={"center"}>
                    <img src={"workScene.jpg"} alt={"WorkScene"} className={classes.textImage}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default Body;