import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import logo from "./logo-blue.png"
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#2D4C8B",
        paddingBottom: "20px"
    },
    logo: {
        height: "80px",
        marginTop: "15px",
        marginLeft: "20px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "auto",
            marginRight: "auto",
            height: "40px",
        }
    },
    contactDetails: {
        fontSize: "16px",
        color: "white",
        fontFamily: "Noto Sans, sans-serif",
        fontWeight: "400",
        letterSpacing: "1px",
        "& a": {
            textDecoration: "none",
            color: "white"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px"
        }
    },
    footNote: {
        marginTop: "20px",
        fontSize: "12px",
        color: "white",
        fontFamily: "Noto Sans, sans-serif",
        fontWeight: "200",
        letterSpacing: "1px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "10px",
            letterSpacing: "0px",
        }
    }
}));


const Contact = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container width={"80%"}>
            <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item container sm={5} direction={"column"} spacing={2} style={{marginTop: "20px"}}>
                    <Grid item className={classes.contactDetails} style={{marginBottom: "30px"}}>
                        <Typography variant={"inherit"} style={{fontSize: "18px", fontWeight: "800"}}>
                            Contact Us
                        </Typography>
                    </Grid>
                    <Grid item className={classes.contactDetails}>
                        <a href="mailto:enquiries@modernstylebuilding.com">
                            <Typography variant={"inherit"}>
                                <EmailIcon style={{verticalAlign: "middle"}}/> enquiries@modernstylebuilding.com
                            </Typography>
                        </a>
                    </Grid>
                    <Grid item className={classes.contactDetails}>
                        <a href="tel:07787520752">
                        <Typography variant={"inherit"}>
                            <CallIcon style={{verticalAlign: "middle"}}/> 07787 520 752
                        </Typography>
                        </a>
                    </Grid>
                    <Grid item className={classes.contactDetails}>
                        <a href="tel:07929010597">
                        <Typography variant={"inherit"}>
                            <CallIcon style={{verticalAlign: "middle"}}/> 07929 101 597
                        </Typography>
                        </a>
                    </Grid>
                </Grid>
                <Grid item container justify={"center"} alignItems={"center"} xs={12} sm={5}>
                    <img src={logo} alt={"modernstylebuildingLogo"} className={classes.logo}/>
                </Grid>
            </Grid>
                <Box className={classes.footNote}>
                    <Typography variant={"inherit"}>
                    Modern Style Building is a Limited Company registered in England and Wales no: 9981977
                    </Typography>
                </Box>
            </Container>
        </div>
    )
};

export default Contact;